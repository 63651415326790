import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Truncate from "../Truncate";

const EventListItemAlternative = ({content}) => (
    <div className="event-list-item card relative h-full bg-primary-light lg:bg-transparent">
        <div className="card-content h-full p-4 lg:py-8">
            <div className="inline-block p-1 mb-2 bg-white text-primary-light text-2xl uppercase">
                {content.dates.startDay}.<span className="font-semibold">{content.dates.startMonth}</span>
            </div>

            <div className="title h3 uppercase text-white hover:text-primary lg:hover:text-primary-light transition-all">
                <Link to={content.path.alias}>{content.title}</Link>
            </div>

            <Truncate
                className="content text-white text-sm"
                limit={120}
                html={(
                    content.body.summary.length > 0
                        ? content.body.summary
                        : content.body.value
                )}
            />
        </div>
    </div>
)

EventListItemAlternative.propTypes = {
    content: PropTypes.object.isRequired,
}

export default EventListItemAlternative
