import React, {useEffect, useState} from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

import TwTitle from "../TailwindCSS/Title";
import EventListItemAlternative from "../ListItems/EventListItemAlternative";

const EventsPreview = () => {
  const data = useStaticQuery(graphql`{
      events: allNodeEvenement(
          sort: {fields: [field_date___value, field_date___end_value], order: ASC},
          filter: {has_image: {eq: true}, is_forthcoming: {eq: true}, status: {eq: true}}
          limit: 3
      ) {
          nodes {
              title
              path {
                  alias
              }
              dates: field_date {
                  startDay: value(formatString: "DD")
                  startMonth: value(formatString: "MM")
              }
              body {
                  summary
                  value
              }
              relationships {
                  image: field_image {
                      localFile {
                          childImageSharp {
                              fluid(maxWidth: 1504, maxHeight: 568, cropFocus: CENTER, quality: 100) {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                      }
                  }
              }
          }
      }
    }`)

  const first = 0;
  const last = data.events.nodes.length - 1;
  const [active, setActive] = useState(first);

  const next = () => {
    setActive(active === last ? first : active + 1);
  }

  const previous = () => {
    setActive(active === first ? last : active - 1);
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      next();
    }, 5000);

    return () => clearTimeout(timeout);
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      next();
    }, 5000);

    return () => clearTimeout(timeout);
  }, [active])

  return (
      <>
        {data.events.nodes.length > 0 ? (
          <div className="relative">
            <div
                className="lg:absolute top-0 inset-x-0 lg:p-8 mb-8 lg:mb-0 z-10">
              <div className="text-center">
                <TwTitle level={2}>Agenda</TwTitle>
                <div
                    className="subtitle font-semibold text-white text-shadow-black">Retrouvez
                  tous les événements de votre commune
                </div>
              </div>
            </div>

            <div className="hidden lg:block images relative z-0 mb-4 lg:mb-0">
              {data.events.nodes.map((node, index) => (
                  <div key={index}
                       className={`${index === active ? ` active` : ` hidden`}`}>
                    <Img
                        fluid={node.relationships.image.localFile.childImageSharp.fluid}
                        alt={node.title}
                    />
                  </div>
              ))}
              <div
                  className={`absolute inset-0 bg-gradient-to-t from-primary via-white to-white mix-blend-multiply`}/>
            </div>

            <div className="lg:absolute bottom-0 inset-x-0 lg:px-8">
              <div className="columns grid grid-cols-1 lg:grid-cols-3 gap-8">
                {data.events.nodes.map((node, index) => (
                    <div key={index}
                         className={`column${index === active ? ` active` : ``}`}>
                      <EventListItemAlternative content={node}/>
                    </div>
                ))}
              </div>
            </div>

            <div className="hidden lg:block">
              <button
                  className="previous absolute top-1/2 left-0 p-2 text-white hover:text-primary-light"
                  onClick={previous}>
                <i className="fas fa-chevron-left text-4xl"><span
                    className="hidden">Previous</span></i>
              </button>

              <button
                  className="next absolute top-1/2 right-0 p-2 text-white hover:text-primary-light"
                  onClick={next}>
                <i className="fas fa-chevron-right text-4xl"><span
                    className="hidden">Next</span></i>
              </button>
            </div>

            <div className="more-link lg:absolute top-0 right-0 lg:p-8 mt-8 lg:mt-0 text-center lg:text-left z-20">
              <Link to="/evenements" className="inline-block px-8 py-2 border border-solid uppercase font-semibold transition duration-300 border-primary-light text-white bg-primary-light hover:border-primary hover:bg-primary">
                Tout l'agenda
              </Link>
            </div>
          </div>
        ) : (
            <></>
        )}
      </>
  )
}

export default EventsPreview
