import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import PublicationListItem from "../ListItems/PublicationListItem";
import TwTitle from "../TailwindCSS/Title";

const PublicationsPreview = () => {
    const data = useStaticQuery(graphql`{
      publications: allNodePublication(
        sort: {fields: title, order: ASC}, 
        filter: {status: {eq: true}, relationships: {field_type_de_publication: {name: {eq: "Publication diverse"}}}},
        limit: 4
      ) {
        nodes {
          title
          created
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 247, maxHeight: 350, cropFocus: CENTER, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            file: field_fichier {
              localFile {
                publicURL
                prettySize
              }
            }
          }
        }
      }
    }`)

    return (
        <div className="relative">
            <div className="my-8">
                <TwTitle level={2}>Publications</TwTitle>
            </div>

            <div className="columns grid grid-cols-1 xl:grid-cols-2 gap-8">
                {data.publications.nodes.map((node, index) => (
                    <div key={index} className="column publication">
                        <PublicationListItem content={node}/>
                    </div>
                ))}
            </div>

            <div className="more-link lg:absolute top-0 right-0 lg:py-8 mt-8 lg:mt-0 text-center lg:text-left z-20">
                <Link to="/publications-diverses" className="button button-primary">Toutes les publications</Link>
            </div>
        </div>
    )
}

export default PublicationsPreview
