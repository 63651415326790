import React from "react";
import PropTypes from "prop-types";

const Title = ({ children, level }) => {
    const classes = "title h2 inline-block px-8 py-1 text-white bg-primary-light transform -rotate-2";

    let content = (
        <span className="block transform rotate-2">{children}</span>
    )

    switch (level) {
        case 1:
        default:
            return <h1 className={classes} data-typesense-field="title">{content}</h1>
        case 2:
            return <h2 className={classes}>{content}</h2>
        case 3:
            return <h3 className={classes}>{content}</h3>
        case 4:
            return <h4 className={classes}>{content}</h4>
        case 5:
            return <h5 className={classes}>{content}</h5>
        case 6:
            return <h5 className={classes}>{content}</h5>
    }
}

Title.propTypes = {
    children: PropTypes.node.isRequired,
    level: PropTypes.number
}

Title.defaultProps = {
    level: 1,
}

export default Title
