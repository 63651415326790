import React from "react";
import QuickAccessMenu from "../Menus/QuickAccessMenu";

const QuickAccess = () => {
    return (
        <div className="bg-primary py-8">
            <div className="skew-primary flex flex-col lg:flex-row py-8">
                <div className="relative w-full lg:w-1/3 p-4">
                    <h2 className="text-white text-left md:text-center lg:text-left">
                        <span className="uppercase">En un clic</span>
                        <sub className="ml-2">
                            <i className="far fa-hand-pointer"><span className="hidden">Icon : En un clic</span></i>
                        </sub>
                    </h2>
                </div>
                <div className="relative w-full lg:w-2/3">
                    <QuickAccessMenu/>
                </div>
            </div>
        </div>
    )
}

export default QuickAccess
