import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import ArticleListItem from "../ListItems/ArticleListItem";
import TwTitle from "../TailwindCSS/Title";

const ArticlesPreview = () => {
    const data = useStaticQuery(graphql`{
        firstArticle: allNodeArticle(
          limit: 1,
          filter: {status: {eq: true}},
          sort: {fields: created, order: DESC}
        ) {
            nodes {
                title
                body {
                    summary
                    value
                }
                path {
                    alias
                }
                relationships {
                    banner: field_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 992, maxHeight: 304, cropFocus: CENTER, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    image: field_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 480, maxHeight: 304, cropFocus: CENTER, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    tags: field_tags {
                        name
                        color: field_color
                        path {
                            alias
                        }
                    }
                }
            }
        }
        articles: allNodeArticle(
          limit: 4,
          skip: 1,
          filter: {status: {eq: true}},
          sort: {fields: created, order: DESC}
        ) {
            nodes {
                title
                body {
                    summary
                    value
                }
                path {
                    alias
                }
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 480, maxHeight: 304, cropFocus: CENTER, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    tags: field_tags {
                        name
                        color: field_color
                        path {
                            alias
                        }
                    }
                }
            }
        }
    }`)


    return (
        <div className="relative">
            <div className="mb-8">
                <TwTitle level={2}>Actualités</TwTitle>
            </div>

            <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {data.firstArticle.nodes.map((node, index) => (
                    <div key={index} className={`column${index === 0 ? ' md:col-span-2' : ''}`}>
                        <ArticleListItem content={node}/>
                    </div>
                ))}

                {data.articles.nodes.map((node, index) => (
                    <div key={(index + 1)} className={`column`}>
                        <ArticleListItem content={node}/>
                    </div>
                ))}
            </div>

            <div className="more-link lg:absolute top-0 right-0 lg:py-8 mt-8 lg:mt-0 text-center lg:text-left z-20">
                <Link to="/actualites" className="button button-primary">Toutes les actualités</Link>
            </div>
        </div>
    )
}

export default ArticlesPreview
