import React from "react";
import {graphql, useStaticQuery, Link} from "gatsby";
import {iconInvisibleText} from "../../Utils/functions";
import MenuLink from "../MenuLink";

const QuickAccessMenu = () => {
    const data = useStaticQuery(graphql`{
        quickAccessMenu: allMenuLinkContentMenuLinkContent(
            filter: {
                enabled: {eq: true}, 
                menu_name: {eq: "main"}
                drupal_parent_menu_item: {eq: "menu_link_content:55776274-fb38-4c95-9afa-9c4c5e36b1d3"}
            },
            sort: {fields: weight, order: ASC}
        ) {
            nodes {
                drupal_parent_menu_item
                drupal_id
                title
                link {
                    uri
                    options {
                        attributes {
                            class
                            target
                            data_has_icon
                            data_icon
                            data_icon_size
                            data_text_invisible
                        }
                    }
                }
            }
        }
    }`)

    return (
        <>
            <ul id="quick-access--menu" className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {data.quickAccessMenu.nodes.map((menu, index) => (
                    <li className="menu-item py-2 px-4" key={index}>
                        <MenuLink internalId={menu.link.uri} className="menu-link flex items-center md:text-lg font-semibold text-white hover:text-primary transition-all">
                            {menu.link.options.attributes && menu.link.options.attributes.data_has_icon ? (
                                <>
                                    <div className="icon inline-flex items-center justify-center w-8 h-8 md:w-12 md:h-12 mr-4 rounded-full bg-white text-primary md:text-xl">
                                        <i className={menu.link.options.attributes.data_icon}>
                                            <span className="hidden">{menu.title}</span>
                                        </i>
                                    </div>
                                    {menu.link.options.attributes.data_text_invisible
                                        ? iconInvisibleText(menu.title)
                                        : menu.title}
                                </>
                            ) : (
                                <>
                                  <div className="icon inline-flex items-center justify-center w-8 h-8 md:w-12 md:h-12 mr-4 rounded-full bg-white text-primary md:text-xl">
                                    <i className="fas fa-link">
                                      <span className="hidden">{menu.title}</span>
                                    </i>
                                  </div>
                                  {menu.title}
                                </>
                            )}
                        </MenuLink>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default QuickAccessMenu
