import React from "react";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Block from "../components/Block";
import ArticlesPreview from "../components/Blocks/ArticlesPreview";
import EventsPreview from "../components/Blocks/EventsPreview";
import PublicationsPreview from "../components/Blocks/PublicationsPreview";
import QuickAccess from "../components/Blocks/QuickAccess";

const IndexPage = () => (
    <Layout isHome>
        <Metas title="Accueil" description="Bienvenue sur le site officiel de la Commune de Rancennes dans les Ardennes (08). Retrouvez tous les services et démarches, les informations pratiques, les actualités et événements de la commune." />
        <Block id="articles-preview--block" container section>
            <ArticlesPreview/>
        </Block>
        <Block id="quick-access-menu--block" container section>
            <QuickAccess/>
        </Block>
        <Block id="events-preview--block" container section>
            <EventsPreview/>
        </Block>
        <Block id="publications-preview--block" container section>
            <PublicationsPreview/>
        </Block>
    </Layout>
)

export default IndexPage;
